import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import DocOpener from "./DocOpener.web";
import TopLogoLogout from "./TopLogoLogout.web";
// Customizable Area End

import DocumentOpenerController from "./DocumentOpenerController.web";
import { Props, configJSON } from "./DocumentOpenerCommonController";

export default class DocumentOpener extends DocumentOpenerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      // <ThemeProvider theme={theme}>
      //   <Container maxWidth={"md"}>
      //     <Box sx={webStyle.mainWrapper}>
      //       <Button
      //         style={webStyle.buttonStyle}
      //         variant="contained"
      //         color="primary"
      //         onClick={() => this.setState({ selectFile: true })}
      //       >
      //         {configJSON.openWithUrl}
      //       </Button>
      //       {this.state.selectFile && (
      //         <DocViewer
      //           style={{ width: "100%" }}
      //           pluginRenderers={DocViewerRenderers}
      //           documents={this.docs}
      //         />
      //       )}
      //     </Box>
      //   </Container>
      // </ThemeProvider>

          <Box style={{
            fontFamily: "Roboto-Medium",
            alignItems: "center",
            paddingBottom: "3%",
            background: "#080B27",
            overflowX: 'hidden'
          }}>
            <Box style={{ overflowX: "hidden" }}>
              <TopLogoLogout navigation={this.props.navigation} id={""} />
              <DocOpener
               navigation={this.props.navigation}
                history={undefined}
                location={undefined}
              />
            </Box>
          </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  buttonStyle: {
    height: "45px",
    marginTop: 10,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
