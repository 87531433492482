import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
  isactiveBtn:any;
}

export interface S {
  // Customizable Area Start
  text: string,
  status: any,
  KeywordAddData:any,
  keywords: any[] , 
  docCount:any,
  clear: boolean
   // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class MainScreenController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  KeywordAdd: any
  KeywordRemoveCallId:any
  GoBackCallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      text: '',
      status: '',
      KeywordAddData:[],
      keywords: [],
      docCount:'',
      clear: false

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentWillMount() {
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    let token = localStorage.getItem('loginSuccessToken')    
    if(!token){
      this.props.navigation.navigate('LoginAccount')
    }
  }   

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.KeywordAdd) {
          if (!responseJson.error) {
            this.setState({ KeywordAddData: [...this.state.KeywordAddData, ...responseJson.data]})
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        if (apiRequestCallId === this.GoBackCallId) {
          if (!responseJson.error) {
            this.setState({ KeywordAddData: [], clear: true, docCount:0 });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
     else if (apiRequestCallId === this.KeywordRemoveCallId) {
            if (!responseJson.error) {
              this.setState({ KeywordAddData: []})
               
            } else {
              this.parseApiErrorResponse(responseJson);
            }
        }
    }
   
    // Customizable Area End
  }


  // Customizable Area Start
  handleTextChange = (event: any) => {
    this.setState({ text: event.target.value });
  };
 
  handleKeywordRemove = (Item:any) => {
    
    let removeToekn = localStorage.getItem("loginSuccessToken")
     const header = {
      "Content-Type": "application/json",
      "token": removeToekn
    };
   
    const data = {
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.KeywordRemoveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_keywordsearch/keywords/${Item}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      KeywordAddData: this.state.KeywordAddData.filter((ele:any)=> ele.id !== Item)
     })
    return true;
}

  handleCount = (files:any) => {
    const filesArr = files.filter((item: any, index: any) => files.indexOf(item) === index)
    this.setState({
      docCount: filesArr?.length
    })
  }

  clearDoc = (e: any) => {
    // Clear the whole Data
    let RemoveFiles = localStorage.getItem("loginSuccessToken")
    e.preventDefault();
    const header = {
        "Content-Type": "application/json",
        "token": RemoveFiles
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GoBackCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/delete_all_documents_and_keywords`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleKeywordAddition = (event: any) => {
    if (event.key === 'Enter') {
      const keyword = this.state.text.split(',').map(str => str.trim());
      this.setState({ keywords: [...this.state.keywords, ...keyword] });
      this.setState({ text: '' });
      localStorage.setItem('keywords', JSON.stringify(this.state.keywords));
      let token = localStorage.getItem("loginSuccessToken")
      const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const attrs = {
        name: keyword
      };
      const data = {
        type: "keyword",
        attributes: attrs,
      };
      const httpBody = {
        data: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.KeywordAdd = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_keywordsearch/keywords`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  }
  // Customizable Area End


}