//@ts-nocheck
import React from "react";

// Customizable Area Start
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Box, Button, Typography, Tab, Tabs, AppBar, TextField } from "@material-ui/core";
import { createTheme, withStyles, ThemeProvider } from "@material-ui/core/styles";
import DocOpenerController from "./DocOpenerController.web";
import SearchIcon from '@material-ui/icons/Search';
const NotFoundImg = require("../assets/image_notofund.png");
import Dialog from "@material-ui/core/Dialog";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
export const CrossIcon = require("../../global_assets/crossblack.png");
import Grid from "@material-ui/core/Grid"
import "./Document.css"
//@ts-ignore
import CustomDocViewer from '../../../components/src/CustomDocViewer';
import { Props } from "./DocumentOpenerCommonController";
import Loader from "../../../components/src/Loader.web";

export default class DocumentOpener extends DocOpenerController {
  GoBackCallId: string ="";
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  SelectAllCheckBox = withStyles({
    root: {
      color: "#7700A6",
      '&$checked': {
        color: "#7700A6",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  handleUnMatchedKeaword = (data: any) => {
    this.setState({ unmatchedKeaword: data })
  }

  async componentWillUnmount() {
    let RemoveFiles = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": "application/json",
      "token": RemoveFiles
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GoBackCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/delete_all_documents_and_keywords`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.props.navigation.navigate('MainScreen')
  }
  // Customizable Area End
  render() {
    console.log("LODING" , this.state.loading)
    return (
      // Customizable Area Start

      <>
        <ThemeProvider theme={theme}>
          <Box sx={{ flexGrow: 1 }} style={{ display: 'flex', paddingBottom: '2rem', justifyContent: 'space-around', backgroundColor: '#080B27', fontFamily: 'Roboto Mono' }}>
            <Box style={{ height: "calc(40em * 1.0325)", display: 'flex', flexDirection: 'column', marginTop: '-2rem', marginLeft: '3rem', backgroundColor: 'white', width: '23%', borderRadius: '0.75rem' }}>
              <Box style={{
                flex: this.state.allKeywords.length > 0 ? 2 : 1, marginBottom: '12px',
                borderTopLeftRadius: '0.75rem',
                borderTopRightRadius: '0.75rem',
                borderBottom: '1px dashed gray',
                paddingBottom: '25px'
              }} >
                <Typography variant='subtitle2' style={{ fontFamily: 'Roboto Mono', fontSize: '1rem', padding: '10px 0px 0px 10px' }}>Keywords</Typography>
                <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '0.75rem', marginBottom: '0.75rem', marginRight: '0.75rem' }}>
                  <TextField variant="standard" margin="normal" required id="keywordSearch"
                    data-test-id="keyword_search"
                    name="keywordSearch"
                    autoFocus
                    placeholder="   Enter Keyword..."
                    value={this.state.keywordText}
                    onChange={this.handleTextChange}
                    onKeyPress={this.handleKeywordAddition}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      disableUnderline: true,
                    }}
                    style={{ boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', padding: '10px', borderRadius: '15px' }}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    data-test-id="apply_keyword"
                    variant="contained"
                    style={webStyle.applyButton}
                    onClick={() => this.handleKeywordAddition('applied')}
                  >
                    apply
                  </Button>
                </div>
              </Box>
              {(this.state.allKeywords.length > 0) && <Box style={{
                overflow: 'auto',
                overflowX: 'hidden',
                borderBottom: '1px dashed gray',
                flex: 5
              }}>
                <Box mx={3.6} display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'>
                  <Box style={webStyle.select_all}>
                    <Typography>Select All</Typography>
                    <this.SelectAllCheckBox
                      checked={this.state.checkedKeyword}
                      onChange={this.onChangeSelectAllCheckbox}
                    />
                  </Box>
                  {this.state.allKeywords.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={item.id}>
                        {item &&
                          <Box p={1} key={item.id} style={{
                            ...webStyle.keyword_Add_box, borderStyle: 'solid', borderWidth: '2px',
                            borderColor: `${item?.keyword_color}`, background: (item?.border_color)
                          }}
                          >
                            <Box display='flex' alignItems='center' width={'inherit'}>
                              <Checkbox
                                data-test-id="item_check_box"
                                size="medium"
                                checked={item.checked}
                                style={{ color: item?.keyword_color }}
                                onChange={(e) => this.onChangeCheckbox(e, item.id)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                              <Typography
                                data-test-id="item_check_box_name"
                                variant='subtitle2'
                                style={{ fontSize: '16px', wordBreak: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'inherit' }}
                              >
                                {item.name}
                              </Typography>
                            </Box>
                            <svg xmlns="http://www.w3.org/2000/svg"
                              onClick={() => {
                                this.handleKeywordRemove(item.id)
                              }}
                              data-test-id="handleWord_remove"
                              onMouseOver={(e) => { e.currentTarget.style.opacity = "0.5" }}
                              onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                              viewBox="0 0 24 24" width="18" height="18" style={{ ...webStyle.keyword_remove_img, fill: item?.keyword_color }}>
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
                            </svg>
                          </Box>
                        }
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Box>
              }
              <Box style={{ alignSelf: 'center', flex: this.state.allKeywords.length === 0 ? 3 : 1, display: 'table-cell', verticalAlign: 'middle' }}>
                {(this.state.allKeywords.length === 0) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={NotFoundImg} />
                </div>
                }
                <div style={{ textAlign: 'center', padding: '15px', lineBreak: "anywhere", maxHeight: "90px", overflow: 'auto', overflowX: 'hidden', }}>
                  <Typography data-test-id="keywords_not_found" variant='subtitle2' style={{ fontSize: '0.8rem' }}>Keywords Not Found.</Typography>
                  {(this.state.unmatchedKeaword.length > 0) &&
                    <Typography data-test-id="keywords_not_found" variant='subtitle2' style={{ fontSize: '1rem', color: "#6c00ea" }}>{this.state.unmatchedKeaword.toString()}</Typography>}
                </div>
              </Box>
            </Box>
            <Box style={{ fontFamily: 'Roboto Mono', marginTop: '-2rem', marginRight: '3rem', marginLeft: '1.3rem', borderRadius: '0.75rem', width: '63%',position: 'relative' }}>
              <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item xs={8}>
                  <AppBar position="static" style={{ background: "#333545", minHeight: 53, borderRadius: '0.25rem' }}>
                    <Tabs value={this.state.value} onChange={this.handleChange}
                      data-test-id="change-tabes"
                      variant="scrollable" scrollButtons="auto" indicatorColor="primary"
                      style={{ minHeight: 53 }} aria-label="scrollable tabs">
                      {
                        this.state.responseData && this.state.responseData.map((files, index) => {
                          return (
                            <Tab
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {this.removeExtension(files?.file_name)}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                      this.handleDocumentRemove(files?.id);
                                    }}
                                    onMouseOver={(e) => { e.currentTarget.style.opacity = "0.5" }}
                                    onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    style={{
                                      marginLeft: '5px',
                                      fill: 'whitesmoke',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
                                  </svg>
                                </Box>
                              }
                              key={index}
                              onClick={() => {
                                this.setState({ indexes: index });
                              }}
                              style={{
                                color: '#fff',
                                fontSize: 13,
                                minHeight: 43,
                                margin: 'auto',
                                marginTop: '7px',
                                padding: '0.25rem'
                              }}
                            />
                          );
                        })}
                    </Tabs>
                  </AppBar>
                </Grid>
                <Grid item xs={4}>
                  <Button style={{ width: '100%', backgroundColor: '#7700A6', color: 'white', height: '53px', fontSize: '0.9rem' }} variant="contained" component="label" data-test-id='Uploadbtn'>
                    + Upload Document
                    <input type="file" data-test-id="multi-file" multiple accept='application/pdf' onChange={this.onFileChange} onClick={(e: any) => { e.target.value = null; }} hidden />
                  </Button>
                </Grid>
              </Grid>
              <Box sx={webStyle.mainWrapper}>
                {(this.state.responseData.length === 0) && <Box style={{ height: '50px' }}>
                  No Documents Found
                </Box>
                }
                {this.state.responseData.filter((matchedIndex, index) => {
                  return true;
                }).map((file, index) => {
                  return (
                    <Box key={index} style={{ width: '100%', borderRadius: '0.75rem', overflow: index === this.state.indexes ? "" : "hidden", height: index === this.state.indexes ? "auto" : "0" }}>
                      {file.url && (
                        <CustomDocViewer
                          fileName={file.file_name}
                          link={file.url}
                          keywordList={(this.state.allKeywords)}
                          allKeywords={this.state.allKeywords}
                          indexes={this.state.indexes}
                          unmatchedKeaword={this.handleUnMatchedKeaword}
                          //documentRef={this.documentRef}
                          setChildRef={(ref: any) => this.handleChildRef(ref, index)}
                        />
                      )}
                    </Box>
                  )
                })
                }
                {this.state.responseData.length !== 0 &&
                  <Button
                    style={{ fontFamily: 'Roboto Mono', width: '50%', marginTop: '15px', marginBottom: '15px', backgroundColor: '#B5E9FF', color: 'black', height: '53px', fontSize: '0.9rem' }}
                    variant="contained" component="label"
                    onClick={this.handleDownload}
                    data-test-id='Downloadbtn'
                  >
                    <strong>Download</strong>
                  </Button>
                }
                  <Box style={webStyle.loaderBox}>
              <Loader
              loading={this.state.loading} />
             </Box>
                
              </Box>
            </Box>
          </Box>
          {
            this.state.pdfValidationPopUp &&
            <Dialog PaperProps={{ style: { borderRadius: 17, boxShadow: "none" }, }} open={this.state.pdfValidationPopUp}
              aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
              <Box style={{ backgroundColor: "#002CFF" }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mr={2} mt={2}
                >
                  <img src={CrossIcon}
                    alt="" style={webStyle.cross_img}
                    onClick={this.PdfPopUpclose}
                    onMouseOver={(e) => { e.currentTarget.style.opacity = "0.5" }}
                    onMouseOut={(e) => e.currentTarget.style.opacity = "1"}
                  />
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="column" p={4} width={420} >
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="h5"
                      style={{ fontFamily: "Roboto Mono", color: "white" }}
                    >
                      Selected one or more files are already exists{" "}
                    </Typography>
                  </Box>
                  <Box display="flex"
                    justifyContent="center"
                    style={{
                      gap: "52px",
                      marginTop: "50px"
                    }}
                  >
                    <Button color="primary" variant="contained"
                      style={webStyle.no_Btn}
                      onClick={this.PdfPopUpclose}
                    >
                      ok
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          }
        </ThemeProvider>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto Mono",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: 'center'
  },
  buttonStyle: {
    height: "2.813rem",
    marginTop: 10,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  applyButton: {
    backgroundColor: '#7700A6',
    color: 'white',
    width: '119px',
    fontSize: '18px',
  },
  cross_img: {
    height: "5vh",
    cursor: 'pointer'
  },
  select_all: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '8%',
    marginRight: '3%',
  },
  keywords_container: {
    height: '50%',
    overflow: 'auto',
    overflowX: 'hidden',
    borderBottom: '1px dashed gray',
    borderTop: '1px dashed gray'
  },
  keyword_Add_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#e9e4f8',
    border: '1px solid #8762f3',
    borderRadius: '6px',
    width: '80%',
    height: '35px',
    margin: '10px'
  },
  keyword_remove_img: {
    marginRight: '1px',
    height: '3vh',
    cursor: 'pointer'
  },
  no_Btn: {
    backgroundColor: "#7700A6",
    color: "white",
    width: "114px",
    fontFamily: "Roboto Mono",
  },
  loaderBox: {
"& .makeStyles-circularContainer-16": {
  paddingLeft:'15% !important'
},
  }
  // Customizable Area End

};


// Customizable Area End
