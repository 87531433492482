import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  IconButton,
  Typography,
  InputAdornment,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Visibility from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const theme = createTheme({
  palette: {
    primary: {
        contrastText: "#fff",
        main: "#fff",
    },
  },
  typography: {
      subtitle1: {
        margin: "20px 0px",
      },
    h6: {
      fontWeight: 500,
    },
  },
});
// Customizable Area End

import TextSummariserController, {
  Props,
  configJSON,
} from "./TextSummariserController";

export default class TextSummariser extends TextSummariserController {

  constructor(props: Props) {

    super(props);
    
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box 
          sx={webStyle.mainWrapper}
          >
            <Typography variant="h6">
                {configJSON.labelTitleText}
            </Typography>
            <Typography 
            variant="subtitle1"
            component="div"
            >
              {configJSON.labelBodyText}
            </Typography>
            <Box sx={webStyle.inputStyle}>
              <InputLabel 
              id="service-shop-name"
              >
                This is the reviced value:{this.state.txtSavedValue}{" "}
              </InputLabel>
              <Input
                type={this.state.enableField ? "password" : "text"}
                data-test-id={"txtInput"}
                fullWidth={true}
                placeholder={configJSON.txtInputPlaceholder}
                value={this.state.txtInputValue}
                onChange={(e) => this.setInputValue(e.target.value)}
                disableUnderline={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.setEnableField}
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {this.state.enableField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box
              onClick={() => this.doButtonPressed()}
              data-test-id="btnAddExample"
              sx={webStyle.buttonStyle}
              component="button"
            >
              <Button color={"primary"}>
                {configJSON.btnExampleTitle}
                </Button>

            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
 
  inputStyle: {
      justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
    height: "100px",
    display: "flex",
    width: "100%",
  },
  mainWrapper: {
      fontFamily: "Roboto-Medium",
      paddingBottom: "30px",
      flexDirection: "column",
      alignItems: "center",
      background: "#fff",
      display: "flex",
    
  },
  buttonStyle: {
      backgroundColor: "rgb(98, 0, 238)",
      height: "45px",
      marginTop: "40px",
      border: "none",
      width: "100%",
  },
};
// Customizable Area End
