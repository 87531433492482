import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes?: any;
}

export interface S {
    // Customizable Area Start
    isAuthenticated: boolean;
    open:boolean;
    goBackModal:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class TopLogoLogoutController extends BlockComponent<
    Props, S, SS
> {

    // Customizable Area Start
    LogoutCallId: any;
    GoBackCallId: any;
    // Customizable Area End
    constructor(props: Props) {

        super(props);

        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];

        this.state = {
            // Customizable Area Start
            isAuthenticated: false,
            open:false,
            goBackModal:false
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

    }

    async componentWillMount() {
        // Customizable Area Start
        const accessToken = localStorage.getItem('loginSuccessToken');
         /* istanbul ignore next */
        if (accessToken) {
            this.setState({ isAuthenticated: true });
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
           

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.LogoutCallId) {
                    if (responseJson.message) {
                        localStorage.removeItem('loginSuccessToken');
                        this.props.navigation.navigate("LoginAccount");
                    }
                    else {
                          /* istanbul ignore next */
                        this.parseApiErrorResponse(responseJson);
                    }             
                }
                if (apiRequestCallId === this.GoBackCallId) {
                    if (responseJson.message) {
                        this.props.navigation.navigate("MainScreen");
                    }
                    else {
                          /* istanbul ignore next */
                        this.parseApiErrorResponse(responseJson);
                    }
                }   
            }

        }
        // Customizable Area End
    }


    // Customizable Area Start

    handleGoBack = () => {
        this.setState({
            goBackModal:true
        })
        // this.props.navigation.navigate('MainScreen')
    }  
    
    handleGoBackClose = () =>{
        if(this.state.goBackModal === true){
            this.setState({
                goBackModal:false
            })
        }
    }

    clearDocTopLogo = (e: any) => {
          /* istanbul ignore next */
        let RemoveFiles = localStorage.getItem("loginSuccessToken")
        e.preventDefault();
        const header = {
            "Content-Type": "application/json",
            "token": RemoveFiles
        };

        const clearDoc_requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
          /* istanbul ignore next */
        this.GoBackCallId = clearDoc_requestMessage.messageId;
        clearDoc_requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/delete_all_documents_and_keywords`
        );
         /* istanbul ignore next */
        clearDoc_requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
       /* istanbul ignore next */
        clearDoc_requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );
         /* istanbul ignore next */
        runEngine.sendMessage(clearDoc_requestMessage.id, clearDoc_requestMessage);
        return true;
    }
        
    handleClickOpen = () => {
        this.setState({
            open:true
        })
      };
      close =()=>{
        if(this.state.open === true){
            this.setState({
                open:false
            })
        }
      }
    logout = async (e: any) => {
        let RemoveFiles = localStorage.getItem("loginSuccessToken")
        e.preventDefault();
        const header = {
            "Content-Type": "application/json",
            "token": RemoveFiles
        };

        if (!RemoveFiles) {
            this.props.navigation.navigate("LoginAccount");
            return
        }
          /* istanbul ignore next */
        const logout_requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.LogoutCallId = logout_requestMessage.messageId;
           /* istanbul ignore next */
        logout_requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/delete_all_documents_and_keywords`
        );
        logout_requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        logout_requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );
          /* istanbul ignore next */
        runEngine.sendMessage(logout_requestMessage.id, logout_requestMessage);
          /* istanbul ignore next */
        localStorage.removeItem("loginSuccessToken")
        return true;
    }
    // Customizable Area End


}
